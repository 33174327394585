<template>
  <div class="tema lottery-table-data">
    <div class="lottery-table-data-screen-box">
      <div class="lottery-table-data-screen"
        @click="showPicker = true">{{ selectDay.label }}</div>
    </div>
    <div class="tables">
      <div class="lottery-table sticky">
        <table cellpadding="0"
          cellspacing="0">
          <thead>
            <tr>
              <th scope="col">属性</th>
              <th scope="col">当前遗漏</th>
              <th scope="col">最大遗漏</th>
              <th scope="col">平均遗漏</th>
              <th scope="col">最大长龙</th>
              <th scope="col">最大每日开出</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in data" :key="row.number + '-' + i">
              <td>{{ row.number }}</td>
              <td>{{ row.now_missing }}</td>
              <td>{{ row.max_missing }}</td>
              <td>{{ row.avg_missing }}</td>
              <td>{{ row.max_long }}</td>
              <td>{{ row.max_appear }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  <picker-user v-model="showPicker" :current="dayIndex" :list="pickerColumns" @confirm="onConfirm"></picker-user>
  </div>
</template>

<script>
import Toast from '../../../mixins/toast'
// import MultipleSelection from '@components/mobile/MultipleSelection'
import { mapState } from 'vuex'
import { Picker, Popup } from 'vant'

export default {
  name: 'Tema',
  mixins: [Toast],
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  props: {
    code: String
  },
  data () {
    return {
      data: [],
      days: [
        {
          label: '今天',
          value: 0
        },
        {
          label: '昨天',
          value: 1
        },
        {
          label: '7日',
          value: 7
        },
        {
          label: '30日',
          value: 30
        },
        {
          label: '90日',
          value: 90
        }
      ],
      dayIndex: 0,
      showPicker: false
    }
  },
  computed: {
    ...mapState('lottery', {
      date: state => state.dataDate
    }),
    day () {
      return this.selectDay.value
    },
    selectDay () {
      return this.days[this.dayIndex]
    },
    pickerColumns () {
      return this.days.map(item => item.label)
    }
  },
  watch: {
    date: function (val, oldVal) {
      this.requestGetHistory()
    },
    dayIndex: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted () {
    this.requestGetHistory()
  },
  methods: {
    onConfirm (value, index) {
      this.showPicker = false
      this.dayIndex = index
    },
    refresh () {
      this.$api.specialCode(this.code, this.day).then((res) => {
        this.data = res.data
      })
    },
    requestGetHistory () {
      this.showLoading()
      this.$api.specialCode(this.code, this.day).then((res) => {
        this.data = res.data
        this.hideToast()
      }).catch((err) => {
        this.showError(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tables {
 
}
// .lottery-table {
//   width: 300px;
//   height: 200px;
//   overflow-x: scroll;
// }
// .sss {
//   position: sticky;
//   left: 0;
//   top: 0;
// }
</style>
