import { Toast } from 'vant'
// 展示时长
const kDuration = 3000

export default {
  methods: {
    showLoading () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
    },
    showInfo (info) {
      Toast({
        message: info,
        duration: kDuration
      })
    },
    showErrorMessage (msg) {
      Toast.fail({
        message: msg,
        duration: kDuration
      })
    },
    showError (error) {
      this.showErrorMessage(error.msg || error)
    },
    showSuccess (success) {
      Toast.success({
        message: success,
        duration: kDuration
      })
    },
    hideToast () {
      Toast.clear()
    }
  }
}
